.icon-list {
  list-style-type: none;
  padding-left: 1rem;

  li {
    margin-bottom: 1rem;
    position: relative;
    padding-left: 4rem;

    i {
      color: $primary;
      font-size: 2em;
      float: left;
      position: absolute;
      top: 1.5rem;
      left: 0;
    }
  }
}
