.section-lang-chooser-big {
  p {
    text-align: center;
    width: 100%;
    margin-bottom: 4rem;
    margin-top: -2rem;

    a {
      font-size: 1rem;
      color: inherit;
      font-weight: bolder;
      margin-left: 1rem;
    }

    a + a {
      margin-left: 1rem !important;
    }

    .choose-text {
      @include media-breakpoint-only(xs) {
        display: block;
      }
    }
  }
}
