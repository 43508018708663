// Global styling for this template

html {
  min-height: 100%;
  scroll-behavior: smooth;
}

body {
  @include break-too-long-words;
}

.text-small {
  font-size: 50%;
}

p img {
  max-width: 100%;
}

table {
  font-family: $font-family-sans-serif;
}

.svg-content-img {
  width: 100%;
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.12);
  margin-top: 2.5 * $spacer;
  margin-bottom: 2.5 * $spacer;
}

a.lang-selector {
  color: #999;
  text-decoration: none;
  font-size: 75%;
}

a.lang-selector + a.lang-selector {
  margin-left: 0.5rem;
}

a:hover.lang-selector {
  text-decoration: underline;
}
