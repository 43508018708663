.section-introduction {
  padding-bottom: 4rem;

  .pcc-animation {
    max-width: 16rem;
    @include media-breakpoint-only(xs) {
      max-width: 60%;
      max-height: 60%;
    }

    float: right;
  }

  blockquote {
    margin: 2rem;
    margin-bottom: 5rem;
  }
}
