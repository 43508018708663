.site-header {
  background-color: rgba(0, 0, 0, 0.85);
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}

.site-header a {
  color: #999;
  transition: ease-in-out color 0.15s;
}

.site-header a:hover {
  color: #fff;
  text-decoration: none;
}

.navbar-dark .navbar-toggler {
  border-color: rgba(255, 255, 255, 0.3);
  background: rgba(255, 255, 255, 0.1);
}

.navbar a {
  font-family: $font-family-sans-serif;
}
