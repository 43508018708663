body,
html {
  background-color: #eee;
}

body {
  $bg-color: rgb(250, 250, 255);
  $dots-color: rgb(240, 240, 245);
  $lines-color: $dots-color;
  background-color: $bg-color;
  //@include pattern-dots($bg-color, $dots-color, $lines-color);
}

.splashBody {
  @include pattern-dots(#ccc, #bbb, #aaa);
}

#top-bg {
  background-color: #303c6c;
}
