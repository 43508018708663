@import "math.scss";
@import "variables.scss";
@import "mixins.scss";
@import "patterns.scss";

@import "bootstrap/scss/bootstrap";

@import "typography.scss";

// Global CSS
@import "global.scss";
@import "layout.scss";
@import "theme.scss";
@import "elements/index.scss";

//Page elements
// @import "header.scss";
@import "navbar.scss";
@import "main.scss";
@import "splash.scss";
@import "404.scss";
@import "sections/index.scss";
