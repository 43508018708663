.section-front-matter {
  $header-right-padding: 4rem;

  padding-top: 2rem;
  padding-bottom: 4rem;
  @include media-breakpoint-up(sm) {
    padding-top: 8rem;
    padding-bottom: 8rem;
  }

  @include media-breakpoint-up(lg) {
    padding-bottom: 10rem;
  }

  header {
    text-align: right;

    padding-right: $header-right-padding;
    @include media-breakpoint-only(xs) {
      padding-right: $header-right-padding/2;
    }

    h2 {
      font-size: 1rem;
      margin-bottom: 0.25rem;
      margin-left: 0.5rem;
      color: #555;
    }

    h1 {
      margin-top: 0;
      margin-bottom: 1rem;
    }
  }

  nav {
    border-left: 1px solid #ddd;

    @include media-breakpoint-only(xs) {
      text-align: right;
      padding-right: $header-right-padding/2;
    }

    ul {
      margin-left: 0;
      padding-left: 0;
    }

    ul li {
      list-style-type: none;
      margin-left: 0;
      padding-left: 0;

      @include media-breakpoint-only(xs) {
        margin-bottom: 0.75rem;
      }
    }
  }

  .go-further {
    font-size: 2em;
  }

  .lang-selector-row {
    display: block;
    margin-bottom: 1rem;
  }
}

@include media-breakpoint-only(xs) {
  .lang-pl .section-front-matter h1 {
    font-size: 2.5rem;
    letter-spacing: -0.04rem;
  }
}
