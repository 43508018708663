h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: $spacer * 2;
}

/*@mixin body-font {
  font-family: "Lato", sans-serif;
}

@mixin heading-font {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
}

html,
body {
  @include body-font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include heading-font;
}*/
/*
html,
body {
  font-size: 10px;
}

h1 {
  margin-bottom: 3rem;
  font-family: Montserrat;
  font-size: 6.103515625em;
  line-height: 9rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0em;
}

h2 {
  margin-bottom: 3rem;
  font-family: Montserrat;
  font-size: 4.8828125em;
  line-height: 6rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0em;
}

h3 {
  margin-bottom: 3rem;
  font-family: Montserrat;
  font-size: 3.90625em;
  line-height: 6rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0em;
}

h4 {
  margin-bottom: 3rem;
  font-family: Montserrat;
  font-size: 3.125em;
  line-height: 6rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0em;
}

h5 {
  margin-bottom: 3rem;
  font-family: Montserrat;
  font-size: 2.5em;
  line-height: 3rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0em;
}

h6 {
  margin-bottom: 3rem;
  font-family: Montserrat;
  font-size: 2em;
  line-height: 3rem;
  font-weight: 600;
  font-style: normal;
  letter-spacing: 0em;
}

p {
  margin-bottom: 3rem;
  font-family: Lora;
  font-size: 1.6em;
  line-height: 3rem;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0em;
}

@media screen and (max-width: 720px) {
  h1 {
    margin-bottom: 3rem;
    font-family: Montserrat;
    font-size: 6.103515625em;
    line-height: 9rem;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0em;
  }
  h2 {
    margin-bottom: 3rem;
    font-family: Montserrat;
    font-size: 4.8828125em;
    line-height: 6rem;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0em;
  }
  h3 {
    margin-bottom: 3rem;
    font-family: Montserrat;
    font-size: 3.90625em;
    line-height: 6rem;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0em;
  }
  h4 {
    margin-bottom: 3rem;
    font-family: Montserrat;
    font-size: 3.125em;
    line-height: 6rem;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0em;
  }
  h5 {
    margin-bottom: 3rem;
    font-family: Montserrat;
    font-size: 2.5em;
    line-height: 3rem;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0em;
  }
  h6 {
    margin-bottom: 3rem;
    font-family: Montserrat;
    font-size: 2em;
    line-height: 3rem;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0em;
  }
  p {
    margin-bottom: 3rem;
    font-family: Lora;
    font-size: 1.6em;
    line-height: 3rem;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0em;
  }
}
*/
