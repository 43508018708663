@mixin break-too-long-words {
  // source: https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  //-ms-word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  /*-ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;*/
}

@mixin a-link {
  color: $primary !important;

  &:hover {
    color: darken($primary, 10%) !important;
  }
}

@mixin shadow($offsetHorizontal, $offsetVertical, $blur, $spread, $color) {
  -webkit-box-shadow: $offsetHorizontal $offsetVertical $blur $spread $color;
  -moz-box-shadow: $offsetHorizontal $offsetVertical $blur $spread $color;
  box-shadow: $offsetHorizontal $offsetVertical $blur $spread $color;
}

@mixin shadowInset($offsetHorizontal, $offsetVertical, $blur, $spread, $color) {
  -webkit-box-shadow: $offsetHorizontal $offsetVertical $blur $spread $color
    inset;
  -moz-box-shadow: $offsetHorizontal $offsetVertical $blur $spread $color inset;
  box-shadow: $offsetHorizontal $offsetVertical $blur $spread $color inset;
}
